import React from "react"
import styled from "styled-components"
import BasicButton from "../../common/buttons/basicButton"
import {graphql, Link, useStaticQuery} from "gatsby"
import { Reveal } from "react-reveal"
import getSingleImage from "../../../utils/getSingleImage"
import { LocalizationContext } from "../../../layout/layout"

const StyledContentWrapperRwd = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  border-width: ${({ latest }) => latest ? "1px" : "0px"};
  border-color: ${({ theme }) => theme.colors.mainNavyBlue};
  border-style: solid;
  width: 100%;
  position: relative;
  margin-top: ${({ latest }) => latest ? "20px" : "0px"};
  margin-bottom: ${({ latest }) => latest ? "50px" : "30px"};
  background: none;
  overflow: hidden;
  &:hover {
    > div.zoomedPicture {
      transform: scale(1.2)
    }
  }

  @media(min-width: 768px){
    flex-direction: row-reverse;
    width: ${({ latest }) => latest ? "100%" : "49%"};
  }
  // &:hover {
  //   *::after {
  //     transition: ${({ latest }) => latest ? "none" : "all 0.4s linear"};
  //     width: ${({ latest }) => latest ? "100%" : "200%"};
  //   }
  // }

`
const ZoomedPictureWrapper = styled.div`
    position: absolute;
    height: 0;
    width: 100%;
    transition: transform .5s;
    background-size: cover;
    background-position: center;
    background-image:url( ${({ bgImg }) => bgImg} );
    @media(min-width: 768px) {
      height: 100%;
    }
`

const StyledImageWrapperRwd = styled.div`
  width: 100%;
  height: 200px;
  display: block;
  background-size: cover;
  background-position: center;
  background-image: url( ${({ bgImg }) => bgImg} );
  
  @media(min-width: 425px){
     height: 250px;
  }
  @media(min-width: 768px){
    width: 100%;
    height: auto;
    background: none;
  }
  @media(min-width: 1200px){
    width: 100%;
    height: auto;
  }

`

const StyledTheRestWrapperRwd = styled.div`
  background-color: ${({ theme, latest }) => latest ? theme.colors.mainWhite : theme.colors.mainNavyBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media(min-width: 768px){
    width: 100%; 
    background-color: ${({ theme, latest }) => latest ? theme.colors.mainWhite : theme.colors.navyBlueOpacity08};
    opacity: 1;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 0;
      background-color: ${({ latest }) => latest ? "white" : "rgba(82,45,237,1)"};
      opacity: .5;
  }

 `

const StyledButtonWrapper = styled.div`
    display: none;
    position: absolute;
    top: 45%;
    left: 50%;
    width: 50%;
    height: 100%;
    @media(min-width: 768px){
      display: ${({ latest }) => latest ? "block" : "none"};
    }
`

const StyledHeaderWrapperRwd = styled.div`
  width: 100%;
  z-index: 2;
  padding: 20px 50px 0 10px;
  @media(min-width: 768px){
  padding-top: 50px;
  padding-left: ${({ latest }) => latest ? "70" : "30"}px;
  padding-right: 30px;   
  }
`

const StyledHeaderRwd = styled.h2`
  font-size: ${({ latest }) => latest ? "20px" : "20px"};
  color: ${({ theme, latest }) => latest ? theme.colors.mainNavyBlue : theme.colors.mainTextLightBlue};
  word-wrap: break-word;
  @media(min-width: 768px){
    font-size: ${({ latest }) => latest ? "40px" : "20px"};
  }

`

const StyledTextWrapperRwd = styled.div`
  width: 100%;
  padding: 20px 50px 0 10px;
  word-wrap: break-word;
  z-index: 3;
  @media(min-width: 768px){
    padding-top: 20px;
    padding-left: ${({ latest }) => latest ? "70" : "30"}px;
    padding-right: 30px; 
  }

`

const StyledTextRwd = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainTextDarkGrey};
  display: ${({ latest }) => latest ? "block" : "none"};
  @media(min-width: 768px){
    font-size: 20px;
    line-height: 30px;
  } 
`

const StyledLogoWrapperRwd = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 50px 20px 10px;
  z-index: 4;
  @media(min-width: 768px){
    padding-left: 70px;
    padding-bottom: 50px;
  }
`

const StyledLogoRwd = styled.img`
  width: 100px;
  height: auto;
  display: ${({ latest }) => latest ? "block" : "none"};
  @media(min-width: 768px){
    margin-left: 0;
    width: 120px;
  }

`
const StyledClientP = styled.p`
  font-size: ${({ latest }) => latest ? "18" : "14"}px;
  line-height: 30px;
  color: ${({ theme, latest }) => latest ? theme.colors.mainNavyBlue : theme.colors.mainTextWhite};
  padding-top: 20px;
 
`

const CaseStudiesModule = ({ latest, entry, translations, globalData, localize }) => {
  const itemSmallCard = entry.archeeCaseStudyWizytowka || {}
  const itemBigCard = entry.archeeCaseStudyWizytowkaDuza || {}
  const item = latest ? itemBigCard[0] : { ...itemSmallCard[0], zdjecie: itemSmallCard[0].zdjecieWTle }
  const clientLogo = (entry.archeeCaseStudyWizytowkaDuza[0] &&
    entry.archeeCaseStudyWizytowkaDuza[0].klient[0] &&
    entry.archeeCaseStudyWizytowkaDuza[0].klient[0].klienci[0] &&
    entry.archeeCaseStudyWizytowkaDuza[0].klient[0].klienci[0].logoKlienta[0] &&
    entry.archeeCaseStudyWizytowkaDuza[0].klient[0].klienci[0].logoKlienta[0].url) || ""


  const globalDependsOnLang = localize.lang === "pl" ? globalData.pl.nodes || "" : globalData.en.nodes || ""
  const readMoreText = globalDependsOnLang?.[0]?.archeeSlowoReadMore || ""
  const clientWord = globalDependsOnLang?.[0]?.archeeSlowoKlient || ""
  const langPrefix = localize.lang === "pl" ? "/" : "/" + localize.lang + "/"

  return (
    <StyledContentWrapperRwd latest={latest} to={langPrefix + entry.uri}>
      <ZoomedPictureWrapper className="zoomedPicture" bgImg={getSingleImage(item.zdjecie)}/>
      <StyledImageWrapperRwd latest={latest} bgImg={getSingleImage(item.zdjecie)}>
        <StyledButtonWrapper latest={latest}>
          <Reveal effect="fadeInUp">
            <BasicButton latest={latest} className="btn-casestudies">{readMoreText}</BasicButton>
          </Reveal>
        </StyledButtonWrapper>
      </StyledImageWrapperRwd>
      <StyledTheRestWrapperRwd latest={latest}>
        <StyledHeaderWrapperRwd latest={latest}>
          <Reveal effect="fadeInUp">
            <StyledHeaderRwd latest={latest}> {item.nazwa}
            </StyledHeaderRwd>
          </Reveal>
        </StyledHeaderWrapperRwd>
        <StyledTextWrapperRwd latest={latest}>
          <Reveal effect="fadeInUp">
            <StyledTextRwd latest={latest}>
              {item.opis}
            </StyledTextRwd>
          </Reveal>
          <Reveal effect="fadeInUp">
            <StyledClientP
              latest={latest}> {clientWord}: {item.klient ? item.klient[0].klienci[0].nazwaKlienta : ""} </StyledClientP>
          </Reveal>
        </StyledTextWrapperRwd>
        <StyledLogoWrapperRwd latest={latest}>
          <Reveal effect="fadeInUp">
            <StyledLogoRwd latest={latest} src={clientLogo}/>
          </Reveal>
        </StyledLogoWrapperRwd>
      </StyledTheRestWrapperRwd>
    </StyledContentWrapperRwd>
  )
}

const LocalizedCaseStudiesModule = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query{
      pl: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeSlowoReadMore
          archeeSlowoKlient
        }
      }
      en: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeSlowoReadMore
          archeeSlowoKlient
        }
      }
    }`);
  return (
      <LocalizationContext.Consumer>
        {localize => <CaseStudiesModule {...props} globalData={data} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedCaseStudiesModule;


